import { Company, CrewMember, DataInstance } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useAuth } from "./useAuth";

export const useCrewMembers = defineStore('crew-members', () => {
    const auth = useAuth();
    const crewMembers = ref(new Map<string, DataInstance<CrewMember.CrewMember>>());
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'crewMembers')
            const subset = collection.all();
            subset.onUpdate(ev => {
                crewMembers.value.clear();
                for(const lz of subset.dataItems()) {
                    crewMembers.value.set(lz.$id, lz);
                }
            })
            await subset.listen();
            unsub.value = () => {subset.cleanup()}
        } else {
            crewMembers.value.clear();
        }
        
        waitingPromises.value.forEach(res => res());
        waitingPromises.value.length = 0;
        loaded.value = true;
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res));
        else return Promise.resolve();
    }

    async function addCrew(model: Partial<CrewMember.CrewMember>) {
        if(!auth.companyId) throw new Error("No companyId!!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'crewMembers')
        const tmp = {...model}
        await collection.add(tmp)
    }

    async function updateCrew(id: string, model: Partial<CrewMember.CrewMember>) {
        if(!auth.companyId) throw new Error("No companyId!!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'crewMembers')
        const tmp = {...model}
        await collection.updateDoc(id, tmp);
    }

    return { crewMembers, loaded, waitForReady, addCrew, updateCrew }
}) 