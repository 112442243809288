// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/functions";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
// import admin from "firebase-admin";
// import {Globals} from "@busy-human/dml-library/dist/js/lib";

import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

import {Globals} from "@busy-human/gearbox";
export const app = initializeApp( CONFIG.firebase.config );

// @ts-ignore firebase-emulators-plugin does not have types
import {install as installEmulators} from "@busy-human/firebase-emulators-plugin/web";


// console.log(firebase.firestore())
export const auth = getAuth(app);
export const realFunctions = getFunctions(app);
export const firestore = getFirestore(app);
export const storage = (bucketUrl?: string) => getStorage(app, bucketUrl);
export const functions = { httpsCallable(funcName: string) {return httpsCallable(realFunctions, funcName)}}
export const analytics = getAnalytics(app);

export { Timestamp } from 'firebase/firestore';

Globals.setFirestore( firestore );

installEmulators({ firestore, functions, auth, config: CONFIG });