import {auth} from "./firebase";

// retrieves what company the user is from.
export function companyClaim(authUser = auth.currentUser){
    if(!authUser){
        return Promise.resolve([]);
    }
    return authUser.getIdTokenResult()
        .then(res => {
            return res.claims
        })
}