import "core-js/stable";
import "regenerator-runtime/runtime"; 

import "./stylesheets/app.scss";

import events from "./events";
// @ts-ignore
import {initEvents} from "@busy-human/vue-component-library/util/events.js";
initEvents(events);
import ArgonDashboard from "./plugins/argon-dashboard";
// @ts-ignore
import VueSignature from "vue-signature-pad";
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";

import { auth } from "./util/firebase";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import router from "./router.js";
import app from "./app.js";
import { createPinia } from "pinia";
const pinia = createPinia();

const options = { containerClassName: "ct-notification" };
import modal from "@busy-human/vue-component-library/components/modal.vue";

import store from "./store";

import { Search } from '@element-plus/icons-vue'

app.use(pinia);

store.actions.checkUser().then(() => {
    app.use(VueSignature);
    app.use(router);
    app.use(Toast, options);
    app.use(ArgonDashboard);
    app.component('el-icon-search', Search);
    // app.use({auth});
    app.component("v-modal", modal);
    app.mount("#application-root");
})
