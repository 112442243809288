import { reactive, computed } from "vue";
import { auth, firestore } from "../util/firebase";
import { companyClaim } from "../util/globals";
import { Company } from "@busy-human/amt-library"; 
const Companies = Company.Companies;;
const { Facilities } = require("@busy-human/amt-library").Facility;
import { where, query } from "firebase/firestore";

var state = reactive({
    companyId: null,
    crewMembers: null,
    units: [],
    unitChanges: [],
    incidents: [],
    incidentChanges: [],
    weatherChanges: [],
    amtIncidents: [],
    landingZones: [],
    user: null,
    users: [],
    messages: [],
    facilities: [],
    aircraft: [],
    isAdmin: false,
    userCollection: null,
    crewMemberCollection: null,
    messageCollection: null,
    unitCollection: null,
    incidentCollection: null,
    landingZoneCollection: null,
    facilitiesCollection: null,
    aircraftCollection: null
})

var listeners = {
    userListener: null,
    messageListener: null,
    unitListener: null,
    incidentListener: null,
    landingZoneListener: null,
    amtIncidentListener: null,
    facilityListener: null,
    aircraftListener: null
}

var getters = {
    getCompany: function () {
        return computed(() => {
            return store.state.companyId;
        })
    },
    isAdmin: function () {
        return computed(() => {
            return store.state.isAdmin;
        })
    },
    getCrewMembers: function () {
        return computed(() => {
            return store.state.crewMembers;
        })
    },
    getUnits: function () {
        return computed(() => {
            return store.state.units;
        })
    },
    getUnitChanges: function () {
        return computed(() => {
            return store.state.unitChanges;
        })
    },
    getWeatherChanges: function () {
        return computed(() => {
            return store.state.weatherChanges;
        })
    },
    getIncidents: function () {
        return computed(() => {
            return store.state.incidents;
        })
    },
    getIncidentChanges: function () {
        return computed(() => {
            return store.state.incidentChanges;
        })
    },
    getAMTIncidents: function () {
        return computed(() => {
            return store.state.amtIncidents;
        })
    },
    getUser: function () {
        return computed(() => {
            return store.state.user;
        })
    },
    getUsers: function () {
        return computed(() => {
            return store.state.users;
        })
    },
    getCrewMembers: function () {
        return computed(() => {
            return store.state.crewMembers;
        })
    },
    getMessages: function () {
        return computed(() => {
            return store.state.messages;
        })
    },
    getLandingZones: () => computed(() => store.state.landingZones),
    getFacilities: () => computed(() => store.state.facilities),
    getAircraft: () => computed(() => store.state.aircraft)
}
var actions = {
    signOut: function () {
        if (listeners.userListener) {
            listeners.userListener();
        }
        if (listeners.messageListener) {
            listeners.messageListener.cleanup();
        }
        if (listeners.unitListener) {
            listeners.unitListener();
        }
        if (listeners.incidentListener) {
            listeners.incidentListener;
        }
        if (listeners.amtIncidentListener) {
            listeners.amtIncidentListener.cleanup();
        }
        if (listeners.landingZoneListener) {
            listeners.landingZoneListener.cleanup();
        }
        if (listeners.facilityListener) {
            listeners.landingZoneListener.cleanup()
        }
        if (listeners.aircraftListener) {
            listeners.aircraftListener.cleanup();
        }


        // state.incidentCollection.cleanup();

        store.state.companyId = null;
        store.state.crewMembers = null;
        store.state.units = [];
        store.state.unitChanges = [];
        store.state.incidents = [];
        store.state.incidentChanges = [];
        store.state.weatherChanges = [];
        store.state.amtIncidents = [];
        store.state.user = null;
        store.state.messages = [];
        store.state.landingZones = [];
        store.state.aircraft = [];
    },
    adminSignOut: function () {
        store.state.isAdmin = false;
    },
    checkUser: function () {
        const checkUserPromise = new Promise((resolve, reject) => {
            auth.onAuthStateChanged(user => {
                if (user) {
                    user.getIdTokenResult().then(res => {
                        if (res.claims.superAdmin === true) {
                            store.state.isAdmin = true;
                            resolve('admin user');
                        } else if (res.claims.userType === 'communicationCenterUser' || res.claims.userType == 'occ') {
                            store.actions.initializeData(user.uid).then(() => {
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    });
                } else {
                    store.state.user = null;
                    resolve();
                }
            });
        });
        return checkUserPromise;
    },

    initializeData: function (userId) {
        const initializePromise = new Promise((resolve, reject) => {

            companyClaim().then(res => {
                if (!res.companyId) {
                    store.state.companyId = auth.currentUser.uid
                } else {
                    store.actions.setCompany(res.companyId);
                }
                state.unitCollection = Companies.subCollectionEntity(store.state.companyId, 'units')
                state.userCollection = Companies.subCollectionEntity(store.state.companyId, 'users')
                state.incidentCollection = Companies.subCollectionEntity(store.state.companyId, 'incidents')
                state.crewMemberCollection = Companies.subCollectionEntity(store.state.companyId, 'crewMembers')
                state.messageCollection = Companies.subCollectionEntity(store.state.companyId, 'messages')
                state.landingZoneCollection = Companies.subCollectionEntity(store.state.companyId, 'landingZones').all()
                state.aircraftCollection = Companies.subCollectionEntity(store.state.companyId, 'aircraft').all();
                state.facilitiesCollection = Facilities.all();

                // If we've resolved a user, and it is NOT an admin, resolve the data for the user
                if (userId && !store.state.isAdmin) {
                    state.userCollection.fetchDoc(userId).then((doc) => {
                        resolve();
                        var userData = doc.data();
                        userData.id = doc.$id;
                        store.state.user = userData;
                    })
                }
                if (userId) {
                    let users = state.userCollection.fetchAll({ listen: true }).then(res => {
                        store.state.users = res.dataItems();
                        res.onUpdate(event => {
                            store.state.users = res.dataItems();
                        })
                    })
                    listeners.userListener = users.cleanup;

                    let unitSubset = state.unitCollection.fetchAll({ listen: true }).then(res => {
                        store.state.units = res.dataItems()
                        return res.onUpdate(event => {
                            event.target.dataItems().forEach(unit => {
                                let index = state.units.findIndex(ele => ele.$id == unit.$id)
                                if (index != -1 && unit.weather != state.units[index].weather) {
                                    store.state.weatherChanges.push(unit)
                                }

                            })
                            store.state.units = res.dataItems()


                        })
                    })

                    listeners.unitListener = unitSubset.cleanup;

                    let messages = state.messageCollection.subset(ref => { return query(ref, where('commId', '==', userId)) })
                    messages.listen()
                    messages.onUpdate(event => {
                        messages.sort((a, b) => {
                            return a.data().timestamp.toMillis() - b.data().timestamp.toMillis()
                        })
                        store.state.messages = messages.dataItems()
                    })
                    listeners.messageListener = messages

                    store.state.landingZoneCollection.listen().then(() => {
                        store.state.landingZones = store.state.landingZoneCollection.dataItems();
                        store.state.landingZoneCollection.onUpdate(event => {
                            store.state.landingZones = store.state.landingZoneCollection.dataItems();
                        })
                    })
                    listeners.landingZoneListener = store.state.landingZoneCollection;

                    store.state.aircraftCollection.listen().then(() => {
                        store.state.aircraft = store.state.aircraftCollection.dataItems();
                        store.state.aircraftCollection.onUpdate(() => {
                            store.state.aircraft = store.state.aircraftCollection.dataItems();
                        })
                    })
                    listeners.aircraftListener = store.state.aircraftCollection;

                    store.state.facilitiesCollection.listen().then(() => {
                        store.state.facilities = store.state.facilitiesCollection.dataItems();
                        store.state.facilitiesCollection.onUpdate(ev => {
                            store.state.facilities = store.state.facilitiesCollection.dataItems();
                        })
                    })
                    listeners.facilityListener = store.state.facilitiesCollection;

                    if (listeners.incidentListener) {
                        listeners.incidentListener();
                        store.state.incidents = [];
                        store.state.incidentChanges = [];
                        store.state.amtIncidents = [];
                    }
                    let incidentSubset = state.incidentCollection.fetchAll({ listen: true }).then(res => {
                        res.onUpdate(event => {
                            store.state.incidents = event.target.dataItems()
                            event.target.dataItems().forEach(incident => {
                                incident.statusLog.forEach((status, index) => {
                                    if (status.companyNotified === false) {
                                        if (status.userId !== store.state.user.$id) {
                                            let changeIndex = store.state.incidentChanges.findIndex(incidentChange => {
                                                return incidentChange.incidentId == incident.$id && incidentChange.logIndex == index && incidentChange.start == status.start
                                            })
                                            if (changeIndex == -1) {
                                                let newLog = status
                                                status.incidentId = incident.$id
                                                status.incidentNumber = incident.number
                                                status.logIndex = index
                                                if (incident.turnDown && incident.turnDown.reason)
                                                    status.turndownReason = incident.turnDown.reason;
                                                store.state.incidentChanges.push(newLog)
                                            }
                                        }
                                    }
                                })
                            })

                        })
                    })
                    listeners.incidentListener = incidentSubset.cleanup;

                    const amtIncidentSubset = state.incidentCollection.subset(ref => {
                        return query(ref, where('amtInfo', '!=', null), where('active', '==', true))
                    })
                    amtIncidentSubset.listen().then(res => {
                        amtIncidentSubset.dataItems().forEach(element => {
                            if (!element.amtInfo.responded) {
                                let amtIncident = {
                                    phone: element.amtInfo.phone,
                                    incidentId: element.$id,
                                    timestamp: element.timeCreated
                                }
                                store.state.amtIncidents.push(amtIncident)
                            }
                        });
                        amtIncidentSubset.onUpdate(event => {
                            store.state.amtIncidents = []
                            event.target.dataItems().forEach(element => {
                                if (!element.amtInfo.responded) {
                                    let amtIncident = {
                                        phone: element.amtInfo.phone,
                                        incidentId: element.$id,
                                        timestamp: element.timeCreated
                                    }
                                    store.state.amtIncidents.push(amtIncident)
                                }
                            });
                        })
                    })
                    listeners.amtIncidentListener = amtIncidentSubset;
                }
            })
        })
        return initializePromise;
    },
    setCompany: function (companyId) {
        store.state.companyId = companyId;


    },
    superAdminAccess: function (companyId, adminId) {
        store.state.companyId = companyId;
        state.unitCollection = Companies.subCollectionEntity(companyId, 'units')
        state.userCollection = Companies.subCollectionEntity(companyId, 'users')
        state.incidentCollection = Companies.subCollectionEntity(companyId, 'incidents')
        state.crewMemberCollection = Companies.subCollectionEntity(companyId, 'crewMembers')
        state.messageCollection = Companies.subCollectionEntity(companyId, 'messages')
        state.landingZoneCollection = Companies.subCollectionEntity(companyId, 'landingZones').all()
        state.aircraftCollection = Companies.subCollectionEntity(companyId, 'aircraft').all()
        state.facilitiesCollection = Facilities.all();

        let users = state.userCollection.fetchAll({ listen: true }).then(res => {
            store.state.users = res.dataItems();
            res.onUpdate(event => {
                store.state.users = res.dataItems()
            })
        })

        let unitSubset = state.unitCollection.fetchAll({ listen: true }).then(res => {
            store.state.units = res.dataItems()
            return res.onUpdate(event => {
                store.state.units = res.dataItems()
            })
        })

        let messages = state.messageCollection.subset(ref => { return query(ref, where('commId', '==', adminId)) })
        messages.listen()
        messages.onUpdate(event => {
            messages.sort((a, b) => {
                return a.data().timestamp.toMillis() - b.data().timestamp.toMillis()
            })
            store.state.messages = messages.dataItems()
        })

        store.state.landingZoneCollection.listen().then(() => {
            store.state.landingZones = store.state.landingZoneCollection.dataItems();
            store.state.landingZoneCollection.onUpdate(event => {
                store.state.landingZones = store.state.landingZoneCollection.dataItems();
            })
        })

        store.state.aircraftCollection.listen().then(() => {
            store.state.aircraft = store.state.aircraftCollection.dataItems();
            store.state.aircraftCollection.onUpdate(() => {
                store.state.aircraft = store.state.aircraftCollection.dataItems();
            })
        })

        store.state.facilitiesCollection.listen().then(() => {
            store.state.facilities = store.state.facilitiesCollection.dataItems();
            store.state.facilitiesCollection.onUpdate(ev => {
                store.state.facilities = store.state.facilitiesCollection.dataItems();
            })
        })


        if (listeners.incidentListener) {
            listeners.incidentListener();
            store.state.incidents = [];
            store.state.incidentChanges = [];
            store.state.amtIncidents = [];
        }
        let incidentSubset = state.incidentCollection.fetchAll({ listen: true }).then(res => {
            res.onUpdate(event => {
                store.state.incidents = event.target.dataItems()
                event.target.dataItems().forEach(incident => {
                    incident.statusLog.forEach((status, index) => {
                        if (status.companyNotified === false) {
                            let changeIndex = store.state.incidentChanges.findIndex(incidentChange => {
                                return incidentChange.incidentId == incident.$id && incidentChange.logIndex == index && incidentChange.start == status.start
                            })
                            if (changeIndex == -1) {
                                let newLog = status
                                status.incidentId = incident.$id
                                status.incidentNumber = incident.number
                                status.logIndex = index
                                store.state.incidentChanges.push(newLog)
                            }
                        }

                    })
                })

            })
        })

        const amtIncidentSubset = state.incidentCollection.subset(ref => {
            return query(ref, where('amtInfo', '!=', null), where('active', '==', true))
        })
        amtIncidentSubset.listen().then(res => {
            amtIncidentSubset.dataItems().forEach(element => {
                if (!element.amtInfo.responded) {
                    let amtIncident = {
                        phone: element.amtInfo.phone,
                        incidentId: element.$id,
                        timestamp: element.timeCreated
                    }
                    store.state.amtIncidents.push(amtIncident)
                }
            });
            amtIncidentSubset.onUpdate(event => {
                store.state.amtIncidents = []
                event.target.dataItems().forEach(element => {
                    if (!element.amtInfo.responded) {
                        let amtIncident = {
                            phone: element.amtInfo.phone,
                            incidentId: element.$id,
                            timestamp: element.timeCreated
                        }
                        store.state.amtIncidents.push(amtIncident)
                    }
                });
            })
        })

    }
}

const store = ({
    state,
    getters,
    actions,
})

export default store;