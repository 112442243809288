import { Company, Units, Users } from "@busy-human/amt-library";
import { type DataInstance } from "@busy-human/gearbox";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useAuth } from "./useAuth";
import { functions } from "@/util/firebase";
import { updateOCCGrant } from "@/util/functions";

export const useUsers = defineStore('users', () => {
    const auth = useAuth();
    const users = ref(new Map<string, DataInstance<Users.User>>());
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'users')
            const subset = collection.all();
            subset.onUpdate(ev => {
                users.value.clear();
                for(const lz of subset.dataItems()) {
                    users.value.set(lz.$id, lz);
                }
                waitingPromises.value.forEach(res => res());
                waitingPromises.value.length = 0;
            })
            await subset.listen();
            loaded.value = true;
            unsub.value = () => {subset.cleanup()}
        } else {
            users.value.clear();
        }
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
    }

    const currentUser = computed(() => users.value.get(auth.currentUID || ''))

    const enrollUserFunc =  functions.httpsCallable("enrollUser")
    async function enrollUser(model: Partial<Users.User>) {
        const res = await enrollUserFunc({
            userInfo: model,
            companyId: auth.companyId,
            adminEmail: auth.user?.email
        });
        if(!res.data.success) throw new Error(res.data?.message);
    }

    const createCrewUnitUserFunc = functions.httpsCallable('createCrewUnitUser');
    async function createCrewUnitUser(model: Partial<Users.User>, password: string) {
        const res = await createCrewUnitUserFunc({
            companyId: auth.companyId,
            crewUser: model,
            password    
        });
        if(res.data.errorInfo) {
            throw new Error("Please try again.");
        }
    }

    async function updateUser(id: string, model: Partial<Users.User>) {
        if(!auth.companyId) throw new Error("No companyId!!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'users')
        const tmp = {...model}
        delete tmp.roles;
        await collection.updateDoc(id, tmp);
        const existing = users.value.get(id);
        if(!!existing?.roles?.includes('occ') !== !!model.roles?.includes('occ')) {
            console.log("OCC changed, updating grant");
            const { data: res } = await updateOCCGrant({companyId: auth.companyId, userId: id, occState: !!model.roles?.includes('occ')});
        } else {
            console.log("OCC not changed, not updating");
        }
    }

    async function assignUsersUnit(unit: DataInstance<Units.Unit>) {
        if(unit.med1Phone?.id) {
            await updateUser(unit.med1Phone.id, { unit: { $id: unit.$id, name: unit.name }})
        }
        if(unit.med2Phone?.id) {
            await updateUser(unit.med2Phone.id, { unit: { $id: unit.$id, name: unit.name }})
        }
        if(unit.mechanicPhone?.id) {
            await updateUser(unit.mechanicPhone.id, { unit: { $id: unit.$id, name: unit.name }})
        }
    }

    async function unassigneUsersUnit(unit: Units.Unit) {
        if(unit.med1Phone?.id) {
            await updateUser(unit.med1Phone.id, { unit: null })
        }
        if(unit.med2Phone?.id) {
            await updateUser(unit.med2Phone.id, { unit: null })
        }
        if(unit.mechanicPhone?.id) {
            await updateUser(unit.mechanicPhone.id, { unit: null })
        }
    }

    return { users, loaded, waitForReady, currentUser, assignUsersUnit, unassigneUsersUnit, enrollUser, updateUser, createCrewUnitUser }
}) 