import { createRouter, createWebHashHistory } from "vue-router";
import store from "./store";
import { auth } from "./util/firebase";
import { useTitle } from "./store/title";
const RE_CONTACT = /\/contact$/i;

if (RE_CONTACT.test(location.hash)) {
  sessionStorage.setItem("targetUrl", "contact");
}

const Dashboard = () => import("./views/Dashboard/Dashboard.vue");
const DashboardLayout = () => import("../src/views/Layout/DashboardLayout.vue");
const CreateIncident = () => import("./views/createIncident.vue");
const LandingPage = () => import("./views/landing-page.vue");
const ViewIncidents = () => import("./views/viewIncidents.vue");
const IncidentReport = () => import("./views/incident-report.vue");
const RAMReport = () => import("./views/ram-report.vue");
const Messaging = () => import("./views/messaging.vue");
const UserList = () => import("./views/user-list.vue");
const Units = () => import("./views/units.vue");
const Crew = () => import("./views/crew.vue");
const Facilities = () => import("./views/facilities.vue");
// const CreateUser = () => import("./views/Components/create-user.vue");
const CreateFacilities = () =>
  import("./views/Components/create-facilities.vue");
// const CreateUnits = () => import("./views/Components/create-units.vue");
const CreateCrews = () => import("./views/Components/Modals/create-crews.vue");
const Settings = () => import("./views/custom-settings.vue");
const SuperAdmin = () => import("./views/superAdmin/super-admin-dashboard.vue");
const OosReport = () => import("./views/oos-reports.vue");
const FlightCompleted = () => import("./views/flight-completed-report.vue");
const LandingZones = () => import("./views/landing-zones-list.vue");
const Aircraft = () => import("./views/aircraft.vue");
const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes: [
    {
      path: "/dashboard",
      component: DashboardLayout,
      name: "Dashboards",
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          components: { default: Dashboard },
        },
        {
          path: "/create-incident",
          component: CreateIncident,
          name: "Create Incident",
        },
        {
          path: "/view-incidents",
          component: ViewIncidents,
          name: "View Incidents",
        },
        {
          path: "/incident-report",
          component: IncidentReport,
          name: "Incident Report",
        },
        { path: "/messaging", component: Messaging, name: "Messaging" },
        { path: "/ram-report", component: RAMReport, name: "RAM Report" },
        { path: "/user-list", component: UserList, name: "User List" },
        { path: "/units", component: Units, name: "Units" },
        { path: "/crew", component: Crew, name: "Crew" },
        { path: "/facilities", component: Facilities, name: "Facilities" },
        {
          path: "/landing-zones",
          component: LandingZones,
          name: "LandingZones",
		  meta: { title: "Landing Zones" }
        },
        // { path: "/create-user", component: CreateUser, name: "Create User" },
        {
          path: "/create-facilities",
          component: CreateFacilities,
          name: "Create Facilities",
        },
        // { path: "/create-units", component: CreateUnits, name: "Create Units" },
        { path: "/create-crews", component: CreateCrews, name: "Create Crews" },
        { path: "/oos-reports", component: OosReport, name: "OOS Report" },
        {
          path: "/flight-completed-reports",
          component: FlightCompleted,
          name: "Flight Reports",
        },
        { path: "/settings", component: Settings, name: "Settings" },
        { path: "/vehicles", component: Aircraft, name: "Vehicles", 
		 },
        { path: "/aircraft", redirect: { name: "Vehicles" } },
        {
          path: "/agencies",
          component: () => import("./views/agency-list.vue"),
          name: "Agencies",
        },
      ],
    },
    {
      path: "/",
      name: "landing",
      component: LandingPage,
    },
    { path: "/super-admin", component: SuperAdmin, name: "Super Admin", meta: { title: "Super Admin Dashboard" }},
  ],
});

router.beforeEach((to, from, next) => {
  let isAdmin = store.getters.isAdmin().value;
  let companyId = store.getters.getCompany();
  if (isAdmin) {
    if (to.fullPath === "/super-admin" || to.fullPath === "/amt-admin") {
      return next();
    } else if (to.name === "landing") {
      return next("/super-admin");
    }
    // else {
    // 	return next('/super-admin')
    // }
  }
  if (companyId.value && !isAdmin && to.fullPath == "/super-admin") {
    return next("/dashboard");
  } else if (companyId.value && to.fullPath !== "/") {
    return next();
  } else if (companyId.value && to.fullPath === "/") {
    return next("/dashboard");
  } else if (!companyId.value && to.fullPath !== "/") {
    return next("/");
  } else {
    return next();
  }
});

router.afterEach((to) => {
  const title = useTitle();
  if (to.meta.title) {
    title.title = to.meta.title;
  } else if(to.name) {
	title.title = to.name;
  } else {
    title.title = "";
  }
});

export default router;
